<template>
    <div class="container-fluid">
        <!-- Page-Title -->
      

        <div class="col-12">
            <div>
                <div class="row mt-2">
                    <div class="col-lg-6 col-md-6 col-sm-6 pb-2">
                        <span class="card-title ">{{ $t('Dashboard.Overview') }} </span>

                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 pb-2 text-end" v-bind:key="randerDropdown">

                        <div class="col">

                        </div>


                        <div class="col-auto">
                            <div class="col-auto">
                                <div class="dropdown">
                                    <a href="javascript:void(0)" class="btn btn-sm btn-outline-light dropdown-toggle"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ overView }}<i class="las la-angle-down ms-1"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <a class="dropdown-item" v-on:click="OverViewFilterFunction('Today')"
                                            href="javascript:void(0);">{{ $t('Dashboard.Today') }} </a>
                                        <a class="dropdown-item" v-on:click="OverViewFilterFunction('Weekly')"
                                            href="javascript:void(0);">{{ $t('Dashboard.Weekly') }}</a>
                                        <a class="dropdown-item" v-on:click="OverViewFilterFunction('Monthly')"
                                            href="javascript:void(0);">{{ $t('Dashboard.Monthly') }}</a>
                                        <a class="dropdown-item" v-on:click="OverViewFilterFunction('6 Monthly')"
                                            href="javascript:void(0);">{{ $t('Dashboard.HalfYear') }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="card report-card">
                            <div class="card-body">
                                <div class="row d-flex justify-content-center">
                                    <div class="col">
                                        <p class="text-dark mb-0 fw-semibold">{{ $t('Dashboard.Sales') }}</p>
                                        <h3 class="m-0">
                                            {{ (parseFloat(income)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}</h3>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="report-main-icon bg-light-alt">
                                            <i data-feather="tag" class="align-self-center text-muted icon-sm"></i>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="card report-card">
                            <div class="card-body">
                                <div class="row d-flex justify-content-center">
                                    <div class="col">
                                        <p class="text-dark mb-0 fw-semibold">Balance</p>
                                        <h3 class="m-0">
                                            {{ (parseFloat(cashTotal)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}</h3>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="report-main-icon bg-light-alt">
                                            <i data-feather="tag" class="align-self-center text-muted icon-sm"></i>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="card report-card">
                            <div class="card-body">
                                <div class="row d-flex justify-content-center">
                                    <div class="col">
                                        <p class="text-dark mb-0 fw-semibold">{{ $t('Dashboard.Expense') }}</p>
                                        <h3 class="m-0">
                                            {{ (parseFloat(expense)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}</h3>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="report-main-icon bg-light-alt">
                                            <i data-feather="zap" class="align-self-center text-muted icon-sm"></i>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="card report-card">
                            <div class="card-body">
                                <div class="row d-flex justify-content-center">
                                    <div class="col">
                                        <p class="text-dark mb-0 fw-semibold">Gross Profit</p>
                                        <h3 class="m-0">
                                            {{ (parseFloat(grossAmount)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}</h3>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="report-main-icon bg-light-alt">
                                            <i data-feather="zap" class="align-self-center text-muted icon-sm"></i>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="card report-card">
                            <div class="card-body">
                                <div class="row d-flex justify-content-center">
                                    <div class="col">
                                        <p class="text-dark mb-0 fw-semibold">{{ $t('Dashboard.Credit') }}</p>
                                        <h3 class="m-0">
                                            {{ (parseFloat(creditAmount)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}</h3>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="report-main-icon bg-light-alt">
                                            <i data-feather="activity" class="align-self-center text-muted icon-sm"></i>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="card report-card">
                            <div class="card-body">
                                <div class="row d-flex justify-content-center">
                                    <div class="col">
                                        <p class="text-dark mb-0 fw-semibold">{{ $t('Dashboard.Purchase') }}</p>
                                        <h3 class="m-0">
                                            {{ (parseFloat(purchase)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}</h3>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="report-main-icon bg-light-alt">
                                            <i data-feather="calendar" class="align-self-center text-muted icon-sm"></i>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div><!--end row-->

                <div class="row">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h4 class="card-title">Sale</h4>
                                    </div><!--end col-->

                                </div> <!--end row-->
                            </div><!--end card-header-->
                            <div class="card-body">
                                <div class="">
                                    <apexchart type="area" height="350" v-bind:key="randerChart"
                                        :options="earningChartOption" :series="earningSeries"></apexchart>
                                </div>
                            </div><!--end card-body-->
                        </div><!--end card-->
                    </div>
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h4 class="card-title">One Day Report</h4>
                                    </div><!--end col-->
                                    <div class="col-auto align-self-center">

                                        <a v-on:click="PrintDetails(false)" class="btn btn-sm btn-outline-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-download align-self-center icon-xs">
                                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                <polyline points="7 10 12 15 17 10"></polyline>
                                                <line x1="12" y1="15" x2="12" y2="3"></line>
                                            </svg>
                                        </a>
                                    </div>

                                </div> <!--end row-->
                            </div><!--end card-header-->
                            <div class="card-body">
                                <div class="accordion" id="accordionExample">

                                    <div class="accordion-item">
                                        <h5 class="accordion-header m-0" id="headingTwo">
                                            <button class="accordion-button collapsed fw-semibold" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                                Purchase (Total Amount: {{
                                                    (parseFloat(invoiceList.totalPurchase)).toFixed(3).slice(0,
                                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                            "$1,") }})
                                            </button>
                                        </h5>
                                        <div id="collapseTwo" class="accordion-collapse collapse"
                                            aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="row">
                                                    <div class="table-responsive">
                                                        <table class="table ">
                                                            <thead class="">
                                                                <tr>
                                                                    <th>#</th>

                                                                    <th class="text-center">
                                                                        {{ $t('InvoicePrintReport.InvoiceNo') }}
                                                                    </th>

                                                                    <th class="text-center">
                                                                        {{ $t('Date') }}
                                                                    </th>

                                                                    <th class="text-center">
                                                                        Product
                                                                    </th>
                                                                    <!-- <th class="text-center">
                                              Amount 
                                            </th> -->
                                                                    <!-- <th class="text-center">
                                                {{ $t('InvoicePrintReport.DisountAmount') }}
                                            </th> -->

                                                                    <!-- <th class="text-center">
                                                {{ $t('InvoicePrintReport.VATAmount') }}
                                            </th> -->

                                                                    <th class="text-center">
                                                                        {{ $t('InvoicePrintReport.TotalAmount') }}
                                                                    </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr v-for="(invoices, index) in invoiceList.purchaseList"
                                                                    v-bind:key="invoices.id">
                                                                    <td class="text-center">
                                                                        {{ index + 1 }}
                                                                    </td>

                                                                    <td class="text-center">
                                                                        {{ invoices.invoiceNo }}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {{ getDate(invoices.date) }}
                                                                    </td>

                                                                    <td class="text-center">

                                                                        <span v-html="invoices.productName"></span>


                                                                    </td>
                                                                    <!-- <td class="text-center">
                                               
                                                <span >
                                                    {{(parseFloat(invoices.amount)).toFixed(3).slice(0,-1)}}
                                                </span>
                                            </td> -->
                                                                    <!-- <td class="text-center">
                                                {{(parseFloat(invoices.discount)).toFixed(3).slice(0,-1)}}
                                            </td> -->
                                                                    <!-- <td class="text-center">
                                                
                                                <span >
                                                   {{(parseFloat(Math.abs(invoices.vaTamount))).toFixed(3).slice(0,-1)}}
                                                </span>
                                            </td> -->


                                                                    <td class="text-center">
                                                                        {{
                                                                            (parseFloat(Math.abs(invoices.totalAmount))).toFixed(3).slice(0,
                                                                                -1) }}
                                                                    </td>

                                                                </tr>
                                                                <tr style="font-size:15px;font-weight:bold;">
                                                                    <td colspan="4" class="text-center">
                                                                        {{ $t('InvoicePrintReport.Total') }}
                                                                    </td>




                                                                    <td class="text-center">
                                                                        {{
                                                                            (parseFloat(invoiceList.totalPurchase)).toFixed(3).slice(0,
                                                                                -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                                    "$1,") }}
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h5 class="accordion-header m-0" id="headingTwo12">
                                            <button class="accordion-button collapsed fw-semibold" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo12"
                                                aria-expanded="false" aria-controls="collapseTwo12">
                                                Sales (Amount: {{
                                                    (parseFloat(invoiceList.totalSales)).toFixed(3).slice(0,
                                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                            "$1,") }})
                                            </button>
                                        </h5>
                                        <div id="collapseTwo12" class="accordion-collapse collapse"
                                            aria-labelledby="headingTwo12" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="row">
                                                    <div class="table-responsive">
                                                        <table class="table ">
                                                            <thead class="">
                                                                <tr>
                                                                    <th>#</th>

                                                                    <th class="text-center">
                                                                        {{ $t('InvoicePrintReport.InvoiceNo') }}
                                                                    </th>

                                                                    <th class="text-center">
                                                                        {{ $t('Date') }}
                                                                    </th>

                                                                    <th class="text-center">
                                                                        Product
                                                                    </th>
                                                                    <!-- <th class="text-center">
                                              Amount 
                                            </th> -->
                                                                    <!-- <th class="text-center">
                                                {{ $t('InvoicePrintReport.DisountAmount') }}
                                            </th> -->

                                                                    <!-- <th class="text-center">
                                                {{ $t('InvoicePrintReport.VATAmount') }}
                                            </th> -->

                                                                    <th class="text-center">
                                                                        {{ $t('InvoicePrintReport.TotalAmount') }}
                                                                    </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr v-for="(invoices, index) in invoiceList.saleList"
                                                                    v-bind:key="invoices.id">
                                                                    <td class="text-center">
                                                                        {{ index + 1 }}
                                                                    </td>

                                                                    <td class="text-center">
                                                                        {{ invoices.invoiceNo }}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {{ getDate(invoices.date) }}
                                                                    </td>

                                                                    <td class="text-center">
                                                                        <span v-html="invoices.productName"></span>


                                                                    </td>
                                                                    <!-- <td class="text-center">
                                               
                                                <span >
                                                    {{(parseFloat(invoices.amount)).toFixed(3).slice(0,-1)}}
                                                </span>
                                            </td> -->
                                                                    <!-- <td class="text-center">
                                                {{(parseFloat(invoices.discount)).toFixed(3).slice(0,-1)}}
                                            </td> -->
                                                                    <!-- <td class="text-center">
                                                
                                                <span >
                                                   {{(parseFloat(Math.abs(invoices.vaTamount))).toFixed(3).slice(0,-1)}}
                                                </span>
                                            </td> -->


                                                                    <td class="text-center">
                                                                        {{
                                                                            (parseFloat(Math.abs(invoices.totalAmount))).toFixed(3).slice(0,
                                                                                -1) }}
                                                                    </td>

                                                                </tr>
                                                                <tr style="font-size:15px;font-weight:bold;">
                                                                    <td colspan="4" class="text-center">
                                                                        {{ $t('InvoicePrintReport.Total') }}
                                                                    </td>




                                                                    <td class="text-center">
                                                                        {{
                                                                            (parseFloat(invoiceList.totalSales)).toFixed(3).slice(0,
                                                                                -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                                    "$1,") }}
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h5 class="accordion-header m-0" id="headingTwo1">
                                            <button class="accordion-button collapsed fw-semibold" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo1"
                                                aria-expanded="false" aria-controls="collapseTwo1">
                                                Expenditure (Amount:{{
                                                    (parseFloat(invoiceList.totalExpense)).toFixed(3).slice(0,
                                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                            "$1,") }})
                                            </button>
                                        </h5>
                                        <div id="collapseTwo1" class="accordion-collapse collapse"
                                            aria-labelledby="headingTwo1" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="row">
                                                    <div class="table-responsive">
                                                        <table class="table ">
                                                            <thead class="">
                                                                <tr>
                                                                    <th>#</th>

                                                                    <th class="text-center">
                                                                        {{ $t('InvoicePrintReport.InvoiceNo') }}
                                                                    </th>

                                                                    <th class="text-center">
                                                                        {{ $t('Date') }}
                                                                    </th>

                                                                    <th class="text-center">
                                                                        Expense Category
                                                                    </th>


                                                                    <th class="text-center">
                                                                        {{ $t('InvoicePrintReport.TotalAmount') }}
                                                                    </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr v-for="(invoices, index) in invoiceList.expenseList"
                                                                    v-bind:key="invoices.id">
                                                                    <td class="text-center">
                                                                        {{ index + 1 }}
                                                                    </td>

                                                                    <td class="text-center">
                                                                        {{ invoices.invoiceNo }}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {{ getDate(invoices.date) }}
                                                                    </td>

                                                                    <td class="text-center">

                                                                        <span v-html="invoices.productName"></span>


                                                                    </td>
                                                                    <!-- <td class="text-center">
                                               
                                                <span >
                                                    {{(parseFloat(invoices.amount)).toFixed(3).slice(0,-1)}}
                                                </span>
                                            </td> -->
                                                                    <!-- <td class="text-center">
                                                {{(parseFloat(invoices.discount)).toFixed(3).slice(0,-1)}}
                                            </td> -->
                                                                    <!-- <td class="text-center">
                                                
                                                <span >
                                                   {{(parseFloat(Math.abs(invoices.vaTamount))).toFixed(3).slice(0,-1)}}
                                                </span>
                                            </td> -->


                                                                    <td class="text-center">
                                                                        {{
                                                                            (parseFloat(Math.abs(invoices.totalAmount))).toFixed(3).slice(0,
                                                                                -1) }}
                                                                    </td>

                                                                </tr>
                                                                <tr style="font-size:15px;font-weight:bold;">
                                                                    <td colspan="4" class="text-center">
                                                                        {{ $t('InvoicePrintReport.Total') }}
                                                                    </td>




                                                                    <td class="text-center">
                                                                        {{
                                                                            (parseFloat(invoiceList.totalExpense)).toFixed(3).slice(0,
                                                                                -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                                    "$1,") }}
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="table-responsive">
                                    <table class="table ">

                                        <tbody>

                                            <tr style="font-size:15px;font-weight:bold;background-color:azure">
                                                <td colspan="4" class="text-center">
                                                    Total Profit(Sales-(Purchase+Expense))
                                                </td>




                                                <td class="text-center">
                                                    {{ (parseFloat(invoiceList.totalProft)).toFixed(3).slice(0,
                                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                            "$1,") }}
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div><!--end card-body-->
                        </div><!--end card-->
                    </div>


                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h4 class="card-title">
                                            {{ $t('Dashboard.InvoiceOverview') }}
                                        </h4>
                                    </div><!--end col-->

                                </div> <!--end row-->
                            </div><!--end card-header-->
                            <div class="card-body">
                                <div class="">
                                    <apexchart type="donut" width="340" height="330" :options="chartOptions"
                                        :series="series9"></apexchart>
                                </div>
                            </div><!--end card-body-->
                        </div><!--end card-->
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h4 class="card-title">
                                            {{ $t('Dashboard.Paid/Un-PaidInvoices') }}
                                        </h4>
                                    </div><!--end col-->

                                </div> <!--end row-->
                            </div><!--end card-header-->
                            <div class="card-body">
                                <div class="">
                                    <apexchart type="pie" width="330" height="330" :options="chartOptionsPaidInvoices"
                                        :series="paidInvoicesSeries"></apexchart>
                                </div>
                            </div><!--end card-body-->
                        </div><!--end card-->
                    </div>


                </div>
            </div>

            <SalePurchasePrint :headerFooter="headerFooter" :show="isShown" @close="isShown = false" :printDetails="invoiceList" v-if="isShown"
                v-bind:key="printRender" />

        </div>


    </div>
</template>
<script>

import clickMixin from '@/Mixins/clickMixin'
import moment from "moment";

export default {


    mixins: [clickMixin],

    data: function () {

        return {
            newCurrency: {
                id: '00000000-0000-0000-0000-000000000000',
                name: '',
                nameArabic: '',
                sign: '',
                type: '',
                image: '',
                isActive: true,
                isDisable: false,
            },
            printRender: 0,
            show: false,
            isShown: false,
            isCaseUser: false,
            headerFooter: '',
            active: 'Dashboard',
            overView: 'Monthly',
            type: '',
            currency: '',
            invoiceList: [],
            randerChart: 0,
            income: 0,
            randerDropdown: 0,
            totalBank: 0,
            totalInvoices: 0,
            totalReturn: 0,
            expense: 0,
            cashTotal: 0,
            grossAmount: 0,
            purchase: 0,
            creditAmount: 0,
            earningSeries: [{
                name: this.$t('Dashboard.Earning'),
                data: []
            }],
            earningChartOption: {
                chart: {
                    height: 350,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'datetime',
                    categories: []
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },

            chartOptions: {
                labels: [this.$t('Dashboard.CreditInvoices'), this.$t('Dashboard.CashInvoices')]
            },

            chartOptionsPaidInvoices: {
                labels: [this.$t('Dashboard.UnPaid'), this.$t('Dashboard.PartiallyPaid'), this.$t('Dashboard.FullyPaid')]
            },
            series9: [],
            paidInvoicesSeries: [],
            creditInvoicesSeries: [],
            chartOptionsCreditInvoices: {
                chart: {
                    width: 380,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.creditInvoicesSeries[opts.seriesIndex]
                    }
                },
                title: {

                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },





            loading: true,

            search: '',

            userID: 0,
            employeeId: 0,
            isChartLoad: false,
            fromDate: moment().format("DD MMM YYYY"),
            toDate: Date()
        }
    },
    watch: {

    },
    methods: {
        PrintDetails: function (download) {

            var root = this;
            if (download) {
                root.isDownload = true;
            }
            else {
                root.isShown = true;
                root.printRender++;
            }
        },
        GetInvoiceRecord: function () {

            var root = this;
            var token = '';
            if (token == '') {
                token = localStorage.getItem('token');
            }
            let url;


            url = '/Report/OneDaySalePurchaseReport?fromDate=';

            let toDate = moment().format("DD MMM YYYY");
            let fromDate = moment().subtract(1, 'days').format('DD MMM YYYY');


            this.$https.get(url + fromDate + '&toDate=' + toDate, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {

                        root.invoiceList = response.data;
                    }
                }).catch(error => {

                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'No Record Exist!',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                });
        },

        makeActive: function (item) {

            this.active = item;
        },
       
        OverViewFilterFunction: function (x) {


            this.overView = x;
            this.GetCashTransaction();
        },
      
        EditprintSetting: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }



            root.$https.get('/Sale/printSettingDetail', { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null && response.data != '') {
                        localStorage.setItem('CashAccountId', response.data.cashAccountId);
                        localStorage.setItem('BankAccountId', response.data.bankAccountId);
                        localStorage.setItem('BankName', response.data.bankName);
                        localStorage.setItem('AccountName', response.data.accountName);
                        localStorage.setItem('AccoutNo', response.data.accoutNo);
                        localStorage.setItem('BranchName', response.data.branchName);
                        localStorage.setItem('BranchName', response.data.branchName);
                        localStorage.setItem('IsHeaderFooter', response.data.isHeaderFooter);



                    } else {
                        console.log("error: something wrong from db.");
                    }
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });

        },
        getDate: function (date) {
            return moment(date).format('l');
        },

        GetCashTransaction: function () {

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            this.$https.get('/Company/GetTransaction?currentDate=' + root.search + '&overViewFilter=' + this.overView + '&activeTab=' + this.active, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {
                    root.EditprintSetting();


                    root.series9 = [];
                    root.paidInvoicesSeries = [];
                    root.earningSeries[0].data = [];
                    root.earningChartOption.xaxis.categories = [];
                    root.income = response.data.income;
                    root.creditAmount = response.data.creditAmount;
                    root.totalInvoices = response.data.totalInvoices;
                    root.totalReturn = response.data.totalReturn;
                    root.purchase = response.data.purchase;
                    root.expense = response.data.expense;
                    root.cashTotal = response.data.cashTotal;
                    root.grossAmount = response.data.grossAmount;
                    root.totalCreditInvoices = response.data.totalCreditInvoices;
                    response.data.earningList.forEach(function (result) {
                        root.earningSeries[0].data.push((parseFloat(result.amount)).toFixed(0));
                        root.earningChartOption.xaxis.categories.push(result.date);
                    });


                    var creditInvoices = parseInt((response.data.totalCreditInvoices / response.data.totalInvoices) * 100);
                    var cashInvoices = parseInt((response.data.totalCashInvoices / response.data.totalInvoices) * 100);
                    root.series9.push(creditInvoices);
                    root.series9.push(cashInvoices);
                    var unPaidInvoices = parseInt((response.data.unPaidInvoices / response.data.totalInvoices) * 100);
                    var partially = parseInt((response.data.partiallyInvoices / response.data.totalInvoices) * 100);
                    var fullyInvoices = parseInt((response.data.fullyInvoices / response.data.totalInvoices) * 100);
                    root.paidInvoicesSeries.push(unPaidInvoices);
                    root.paidInvoicesSeries.push(partially);
                    root.paidInvoicesSeries.push(fullyInvoices);
                    root.randerChart++;


                }
            });
        },

       

        
       
    },
    created: function () {

        this.$emit('input', this.$route.name);
    },
    mounted: function () {
        
        this.currency = localStorage.getItem('currency');

        this.fromDate = moment().startOf('month').format("DD MMM YYYY");

        if (this.$session.exists()) {
            this.userID = localStorage.getItem('UserID');
            this.employeeId = localStorage.getItem('EmployeeId');
            this.fromDate = moment().startOf('month').format("DD MMM YYYY");
            //this.isDayStart();

            // this.getDataSaleIncome();
        }
        {


            this.GetCashTransaction();
            this.GetInvoiceRecord();
            this.search = moment().format("DD MMM YYYY");

            this.chartbymonth = moment().format("DD MMM YYYY");
            this.rander++;
            this.randerDropdown++;
        }
       



    },
}
</script>