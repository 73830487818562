<template>
    <div class="row">
        <div class="col-lg-12 ">

            <div class="row">

                <div class="card-body">
                    <div class="dastone-profile">
                        <div class="row">
                            <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                                <div class="dastone-profile-main">
                                    <div class="dastone-profile-main-pic">
                                        <img v-if="newCustomer.profileImage != null && newCustomer.profileImage != ''"
                                                        class="float-right"
                                                        :src="'data:image/png;base64,' + newCustomer.profileImage"
                                                        width="100" />
                                        <img v-else src="assets/images/users/user-4.jpg" alt="" height="110"
                                            class="rounded-circle">
                                        <span class="dastone-profile_main-pic-change" >
                                            <i class="fas fa-camera"></i>
                                        </span>
                                    </div>
                                    <div class="dastone-profile_user-detail">
                                        <h5 class="dastone-user-name">{{ newCustomer.englishName }}</h5>

                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-8 text-end">

                                <button class="btn btn-outline-primary"
                                                v-on:click="EditCustomer(true)">Add New Case</button>


                            </div><!--end col-->
                            <div class="col-lg-4 text-end">

                            </div>
                        </div><!--end row-->
                    </div><!--end f_profile-->
                </div><!--end card-body-->

            </div>

        </div>
        <div class="card">
            <div class="card-body">


                <div class="row ">
                    <div class="col-lg-12">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#home" role="tab"
                                    aria-selected="true">Information</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#Remarks" role="tab"
                                    aria-selected="false">Case History</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#PaymentVoucher" role="tab"
                                    v-on:click="paymentVoucherClick()" aria-selected="false">Payment History</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#contract" role="tab"
                                    aria-selected="false">Authority Letter</a>
                            </li> -->


                            <li class="nav-item"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && havePermission('Contact Email') && !isCaseUser">
                                <a class="nav-link" data-bs-toggle="tab" href="#email" role="tab"
                                    aria-selected="false">Email</a>
                            </li>

                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane p-3 active" id="home" role="tabpanel">

                                <div class="row" v-if="!isEdit">

                                    <div class="row">
                                        <label class="col-form-label text-end col-lg-2">
                                            Name:
                                        </label>
                                        <div class="inline-fields text-start col-lg-6 mt-2">
                                            {{ newCustomer.name }}


                                        </div>
                                        <div class="inline-fields text-end col-lg-4 mt-2">
                                            <button class="btn btn-outline-primary"
                                                v-on:click="EditCustomer(true)">Edit</button>



                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-form-label text-end col-lg-2">
                                            Date of Birth:
                                        </label>
                                        <div class="inline-fields text-start col-lg-6 mt-2">
                                            {{ newCustomer.dob }}


                                        </div>
                                    </div>
                                    <div class="row ">
                                        <label class="col-form-label text-end col-lg-2">
                                            Phone:
                                        </label>
                                        <div class="inline-fields text-start col-lg-6 mt-2">
                                            {{ newCustomer.mobile }}


                                        </div>
                                    </div>
                                    <div class="row ">
                                        <label class="col-form-label text-end col-lg-2">
                                            Email:
                                        </label>
                                        <div class="inline-fields text-start col-lg-6 mt-2">
                                            {{ newCustomer.email }}


                                        </div>
                                    </div>
                                    <div class="row ">
                                        <label class="col-form-label text-end col-lg-2">
                                            Address:
                                        </label>
                                        <div class="inline-fields text-start col-lg-6 mt-2">
                                            {{ newCustomer.address }}


                                        </div>
                                    </div>




                                </div>
                                <div class="row" v-else>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <label>Name: <span class="text-danger"> *</span></label>

                                            <input v-model="newCustomer.englishName"
                                                :placeholder="$t('AddCustomer.EnglishName')" class="form-control"
                                                type="text">

                                        </div>
                                        <div class="col-lg-4">


                                            <label v-if="isCaseUser">Date of Birth: <span class="text-danger">
                                                    *</span></label>
                                            <label v-else>Registration Date: <span class="text-danger"> *</span></label>

                                            <datepicker v-model="newCustomer.registrationDate" v-bind:key="randerDate">
                                            </datepicker>

                                        </div>
                                        <div class="col-lg-4">


                                            <label>Phone No: </label>

                                            <input placeholder="" v-model="newCustomer.contactNo1" class="form-control"
                                                type="text">

                                        </div>
                                        <div class="col-lg-4">


                                            <label>Telephone No: </label>

                                            <input placeholder="" v-model="newCustomer.telephone" class="form-control"
                                                type="text">

                                        </div>
                                        <div class="col-lg-4" v-if="isCaseUser">
                                            <label>{{ $t('AddCustomer.Email') }}</label>
                                            <input v-model="newCustomer.email" disabled class="form-control"
                                                type="text">


                                        </div>
                                        <div class="col-lg-4" v-else>
                                            <label>{{ $t('AddCustomer.Email') }}</label>
                                            <input v-model="newCustomer.email" class="form-control" type="text">


                                        </div>
                                        <div class="col-lg-4">
                                            <label>{{ $t('AddCustomer.Address') }}</label>
                                            <textarea v-model="newCustomer.billingAddress" rows="3"
                                                class="form-control "></textarea>
                                        </div>




                                    </div>
                                    <div class=" col-lg-12 invoice-btn-fixed-bottom">
                                        <div class="row">
                                            <div class=" col-md-12">


                                                <div class="button-items">

                                                    <button class="btn btn-outline-primary"
                                                        v-bind:disabled="$v.newCustomer.$invalid"
                                                        v-on:click="SaveCustomer"><i class="far fa-save "></i> {{
                                            $t('AddCustomer.btnUpdate')
                                        }}</button>
                                                    <button class="btn btn-danger" v-on:click="Cancel">{{
                                            $t('AddCustomer.Cancel') }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>



                            </div>
                            <div class="tab-pane p-3" id="profile" role="tabpanel">


                            </div>
                            <div class="tab-pane p-3" id="settings" role="tabpanel">

                                //Details

                            </div>
                            <div class="tab-pane p-3" id="Address" role="tabpanel">

                                //Address

                            </div>


                            <div class="tab-pane p-3" id="Remarks" role="tabpanel">
                                <div class="row">
                                        <div class="form-group col-12 text-end" v-if="showList">
                                           <button  class="btn btn-primary" v-on:click="showList = false">Add New Case</button>
                                        </div>
                                        <div class="form-group col-12 text-end" v-else>
                                           <button  class="btn btn-danger" v-on:click="showList = true">Cancel</button>
                                        </div>
                                    </div>
                                <div v-if="showList">
                                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th width="5%">#</th>
                                    
                                    <th >
                                        Case Name
                                    </th>
                                    <th >
                                        Case Status
                                    </th>

                                    <th >
                                        Payment Status
                                    </th>
                                    <th width="30%">
                                       Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(contact,index) in newCustomer.contactProductModels" v-bind:key="contact.id">
                                    <td >
                                        {{index+1}}
                                    </td>
                                    <td >
                                        <strong>
                                            {{ contact.productName }}
                                        </strong>
                                    </td>
                    
                                    <td>{{ contact.caseStausName }}</td>

                                    <td>
                                        Due: {{ currency }}{{ contact.dueAmount }},
                                        Paid: {{ currency }}{{ contact.receivedAmount }}<br/>
                                        <button v-if="contact.caseStatus == 1" type="button" class="btn btn-light"
                                                v-on:click="makePayment(contact)">Make Payment</button>
                                        
                                    </td>

                                    <td>
                                        <button v-if="contact.caseStatus == 1 || contact.caseStatus == 3" type="button" class="btn btn-light me-2"
                                                v-on:click="viewAttachment(contact)">Attachment</button>
                                        <button  type="button" class="btn btn-light me-2"
                                                v-on:click="viewLoa(contact)"> LOA</button>
                                                <!-- <button  type="button" class="btn btn-light me-2"
                                                v-on:click="viewLoa(contact)">Invoice</button> -->
                                        <button  type="button" class="btn btn-light me-2" v-if="contact.caseStatus == 4"
                                                v-on:click="stripePayment(contact)"> Stripe Payment</button>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                                </div>
                                <div v-if="!showList">
                                    <div class="row" v-if="!generateAuthority">
                                    <div class="row justify-content-center">
                                        
                                        <div class="form-group col-4 text-center">
                                            <label>
                                                Select the case :
                                            </label>
                                            <div>
                                                <productMasterdropdown @input="getValue" :values="newCustomer.productId"
                                                    v-model="newCustomer.productId">
                                                </productMasterdropdown>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="form-group col-4 text-center" >
                                           <button :disabled="newCustomer.productId == null || newCustomer.productId == undefined || newCustomer.productId == '00000000-0000-0000-0000-000000000000'" class="btn btn-primary" v-on:click="generateAuthority = true">Generate Letter of authority</button>
                                        </div>
                                    </div>

                                   
                                </div>
                                <div class="row justify-content-center" v-if="generateAuthority">
                                    <div class="col-4">
                                        <div class="row justify-content-center col-lg-6">
                                       Letter of authority
                                    </div>
                                    <div class="row ">
                                        <label class="col-form-label text-start col-lg-4">
                                            Name:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ newCustomer.name }}


                                        </div>
                                       
                                    </div>
                                    <div class="row ">
                                        <label class="col-form-label text-start col-lg-4">
                                            Date of Birth:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ newCustomer.dob }}


                                        </div>
                                    </div>
                                    <div class="row ">
                                        <label class="col-form-label text-start col-lg-4">
                                            Phone:
                                        </label>
                                        <div class="inline-fields text-start col-lg-6 mt-8">
                                            {{ newCustomer.mobile }}


                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-form-label text-start col-lg-4">
                                            Email:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ newCustomer.email }}


                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-form-label text-start col-lg-4">
                                            Address:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ newCustomer.address }}


                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-form-label text-start col-lg-4">
                                            Case Type:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ caseTypeName }}


                                        </div>
                                    </div>
                                    <div class="row" v-if="showSignatureField">
                                        <label class="col-form-label text-start col-lg-4">
                                                Signature Type :
                                            </label>
                                            <div class="inline-fields text-start col-lg-8 ">
                                                <multiselect v-model="newCustomer.signatureType" v-on:input="onSignatureTypeChange"
                                                    :preselect-first="false" :options="['name', 'image']"
                                                    :show-labels="false" placeholder="Select Signature Type">
                                                </multiselect>
                                            </div>
                                    </div>
                                    <div class="row mt-2" v-if="showSignatureField">
                                        <div v-if="newCustomer.signatureType == 'name'" class="form-group col-12">
                                            <!-- <label>Name</label> -->
                                            <input class="form-control" v-model="newCustomer.signatureName"
                                                type="text" />
                                        </div>

                                        <div class="form-group col-12" v-if="newCustomer.signatureType == 'image'">
                                            <div :key="renderImg">
                                                <div class="input-group mb-3"
                                                    v-if="!((imageSrc == '' && newCustomer.signaturePath != '') || (imageSrc != '' && newCustomer.signaturePath == '') || (imageSrc != '' && newCustomer.signaturePath != ''))">
                                                    <input ref="imgupload" type="file" class="form-control"
                                                        id="inputGroupFile02" @change="uploadImage(false)" accept="image/*"
                                                        name="image">
                                                </div>



                                                <div class="text-right " v-if="imageSrc != ''">
                                                    <img v-if="imageSrc != ''" class="float-right" :src="imageSrc"
                                                        width="100" />
                                                </div>
                                                <div v-else class="text-right ">
                                                    <img v-if="newCustomer.signaturePath != null && newCustomer.signaturePath != ''"
                                                        class="float-right"
                                                        :src="'data:image/png;base64,' + newCustomer.signaturePath"
                                                        width="100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-12"
                                            v-if="imageSrc != '' || newCustomer.signaturePath != ''">
                                            <div class="text-right">
                                                <button class="btn btn-danger  btn-sm" v-on:click="removeImage()">{{
                                            $t('AddCurrency.Remove')
                                                    }}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-6 text-start" >
                                           <button :disabled="newCustomer.productId == null || newCustomer.productId == undefined || newCustomer.productId == '00000000-0000-0000-0000-000000000000'" class="btn btn-primary" v-on:click="showSignatureField = !showSignatureField">Add Signature</button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-12 text-end" >
                                           <button :disabled="newCustomer.productId == null || newCustomer.productId == undefined || newCustomer.productId == '00000000-0000-0000-0000-000000000000'" class="btn btn-primary" v-on:click="GenerateAndSendCode">Generate & send</button>
                                        </div>
                                    </div>
                                    </div>

                                </div>
                                </div>

                            </div>
                            <div class="tab-pane p-3" id="contract" role="tabpanel">
                                <div class="row justify-content-center">
                                    <div class="col-4">
                                        <div class="row justify-content-center col-lg-6">
                                       Letter of authority
                                    </div>
                                    <div class="row ">
                                        <label class="col-form-label text-start col-lg-4">
                                            Name:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ newCustomer.name }}


                                        </div>
                                       
                                    </div>
                                    <div class="row ">
                                        <label class="col-form-label text-start col-lg-4">
                                            Date of Birth:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ newCustomer.dob }}


                                        </div>
                                    </div>
                                    <div class="row ">
                                        <label class="col-form-label text-start col-lg-4">
                                            Phone:
                                        </label>
                                        <div class="inline-fields text-start col-lg-6 mt-8">
                                            {{ newCustomer.mobile }}


                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-form-label text-start col-lg-4">
                                            Email:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ newCustomer.email }}


                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-form-label text-start col-lg-4">
                                            Address:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ newCustomer.address }}


                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-form-label text-start col-lg-4">
                                            Case Type:
                                        </label>
                                        <div class="inline-fields text-start col-lg-8 mt-2">
                                            {{ caseTypeName }}


                                        </div>
                                    </div>
                                    <div class="row" >
                                        <label class="col-form-label text-start col-lg-4">
                                                Signature Type :
                                            </label>
                                            <div class="inline-fields text-start col-lg-8 ">
                                                <multiselect v-model="newCustomer.signatureType" v-on:input="onSignatureTypeChange"
                                                    :preselect-first="false" :options="['name', 'image']"
                                                    :show-labels="false" placeholder="Select Signature Type">
                                                </multiselect>
                                            </div>
                                    </div>
                                    <div class="row mt-2" >
                                        <div v-if="newCustomer.signatureType == 'name'" class="form-group col-12">
                                            <!-- <label>Name</label> -->
                                            <input class="form-control" v-model="newCustomer.signatureName"
                                                type="text" />
                                        </div>

                                        <div class="form-group col-12" v-if="newCustomer.signatureType == 'image'">
                                            <div :key="renderImg">
                                                <div class="input-group mb-3"
                                                    v-if="!((imageSrc == '' && newCustomer.signaturePath != '') || (imageSrc != '' && newCustomer.signaturePath == '') || (imageSrc != '' && newCustomer.signaturePath != ''))">
                                                    <input ref="imgupload" type="file" class="form-control"
                                                        id="inputGroupFile02" @change="uploadImage(false)" accept="image/*"
                                                        name="image">
                                                </div>



                                                <div class="text-right " v-if="imageSrc != ''">
                                                    <img v-if="imageSrc != ''" class="float-right" :src="imageSrc"
                                                        width="100" />
                                                </div>
                                                <div v-else class="text-right ">
                                                    <img v-if="newCustomer.signaturePath != null && newCustomer.signaturePath != ''"
                                                        class="float-right"
                                                        :src="'data:image/png;base64,' + newCustomer.signaturePath"
                                                        width="100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-12"
                                            v-if="imageSrc != '' || newCustomer.signaturePath != ''">
                                            <div class="text-right">
                                                <button class="btn btn-danger  btn-sm" v-on:click="removeImage()">{{
                                            $t('AddCurrency.Remove')
                                                    }}</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane p-3" id="Sale" role="tabpanel">
                                <div class="row">



                                </div>
                            </div>
                            <div class="tab-pane p-3" id="PaymentVoucher" role="tabpanel">


                                <div class="row">
                                    <!-- <div class="col-8" v-if="newCustomer.runningBalance > 0">
                                        <label>{{ newCustomer.runningBalance.startsWith('Dr') ? 'Due Balance' : 'Pay Balance' }}: <span style="font-weight: bold;font-size: larger;"> {{ newCustomer.runningBalance }}</span></label>


                                    </div> -->
                                    <div class="col-8"></div>
                                    <div class="col-4">
                                        <!-- <div class="col-auto align-self-center">
                                            <a v-on:click="showPV = true" href="javascript:void(0);"
                                                class="btn btn-sm btn-outline-primary mx-1">
                                                <i class="align-self-center icon-xs ti-plus"></i>
                                                {{ $t('PaymentVoucherList.AddNew') }}
                                            </a>

                                        </div> -->
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead class="thead-light table-hover">
                                                <tr>
                                                    <th style="width:40px;">#</th>
                                                    <th style="width:150px;">
                                                        {{ $t('PaymentVoucherList.VoucherNumber') }}
                                                    </th>
                                                    <th style="width:130px;">
                                                        {{ $t('PaymentVoucherList.CreatedDate') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('Voucher Date') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.CreatedBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.DraftBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.PaymentMode') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.PaymentType') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.BankCashAccount') }}

                                                    </th>
                                                    <th>
                                                        <span
                                                            v-if="formName == 'CashReceipt' || formName == 'AdvanceReceipt' || formName == 'BankReceipt' || formName == 'PettyCash'">
                                                            {{ $t('PaymentVoucherList.CustomerAccount') }}
                                                        </span>
                                                        <span
                                                            v-if="formName == 'BankPay' || formName == 'AdvancePurchase' || formName == 'CashPay'">
                                                            {{ $t('PaymentVoucherList.SupplierAccount') }}
                                                        </span>
                                                    </th>
                                                    <th v-if="allowBranches">
                                                        {{ $t('DailyExpense.BranchCode') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.NetAmount') }}
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(voucher, index) in vouchersList" v-bind:key="voucher.id">

                                                    <td>
                                                        {{ index + 1 }}
                                                    </td>

                                                    <td
                                                        v-if="(isValid('CanEditPettyCash') && formName == 'PettyCash') || (isValid('CanEditCPR') && (formName == 'BankReceipt' || formName == 'AdvanceReceipt') || formName == 'AdvanceReceipt') || (isValid('CanEditSPR') && formName == 'BankPay' || formName == 'AdvancePurchase')">
                                                        <strong>
                                                            <a href="javascript:void(0)"
                                                                v-on:click="EditPaymentVoucher(voucher.id)">
                                                                {{ voucher.voucherNumber }}
                                                            </a>
                                                        </strong>
                                                    </td>
                                                    <td v-else>
                                                        {{ voucher.voucherNumber }}
                                                    </td>
                                                    <td>
                                                        {{ getDate(voucher.date) }}
                                                    </td>
                                                    <td>
                                                        {{ getDate(voucher.paymentDate) }}
                                                    </td>
                                                    <td>
                                                        {{ voucher.draftBy }}
                                                    </td>
                                                    <td>
                                                        {{ voucher.draftBy }}
                                                    </td>
                                                    <td>
                                                        <div class="badge badge-soft-primary"
                                                            v-if="voucher.paymentMode == 0">
                                                            {{ ($i18n.locale == 'en' || isLeftToRight()) ? ' Cash' : '' }}

                                                        </div>
                                                        <div class="badge badge-soft-success"
                                                            v-if="voucher.paymentMode == 1">
                                                            {{ ($i18n.locale == 'en' || isLeftToRight()) ? ' Bank' : 'مصرف'}}
                                                        </div>
                                                        <div class="badge badge-soft-info" v-if="voucher.paymentMode == 5">
                                                            {{ ($i18n.locale == 'en' || isLeftToRight()) ? ' Advance' :
                                                                'يتقدم' }}
                                                        </div>
                                                    </td>
                                                    <td v-if="voucher.paymentMethods == 'Default'">
                                                        ---
                                                    </td>
                                                    <td v-else>
                                                        {{ voucher.paymentMethods }}
                                                    </td>
                                                    <td>
                                                        {{ voucher.bankCashAccountName }}
                                                    </td>

                                                    <td>
                                                        {{ voucher.contactAccountName }}
                                                    </td>

                                                    <td v-if="allowBranches">
                                                        {{ voucher.branchCode }}
                                                    </td>
                                                    <td>
                                                        {{ currency }}
                                                        {{ parseFloat(voucher.amount).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>


                        </div>
                        <div class="tab-content tab-space tab-subcategories">

                        </div>

                    </div>
                </div>

                <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>


            </div>

            <bulk-attachment :isCaseUser="true" :attachmentList="cusSaleDetail.attachmentList"  :documentid="caseId" :show="isShowAttachment" v-if="isShowAttachment" @close="attachmentSave" />

            <DesignForAuthority  :show="showLoa"  v-if="showLoa" @close="onCloseLoa" :customerDetail="cusSaleDetail" :caseRecord="newCustomer" :caseUser="true"></DesignForAuthority>


            <paymentVoucherModel :formName="'BankReceipt'" :show="showVoucher" v-if="showVoucher" @close="CloseVoucher"
            :accId="newCustomer.accountId" :runningBalanceProp="newCustomer.runningBalance" :invoiceId="cusSaleDetail.saleId"
            :totalAmount="cusSaleDetail.netAmount" :remainingBalance="cusSaleDetail.dueAmount"></paymentVoucherModel>
        </div>

    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import {
    required,
    maxLength,
} from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default ({
    mixins: [clickMixin],
    components: {
        Loading,
        Multiselect
    },
    data: function () {
        return {
            currency:'',
            showLoa:false,
            formData: {
                name: 'Ahsan Zafar',
                dob: '', // Date format YYYY-MM-DD
                phone: '+400000000',
                email: 'ad@gmail.com',
                address: '17 Ali Brother, Sylhet',
            },
            cusSaleDetail:'',
            showList:true,
            caseId:'',
            showVoucher:false,
            isShowAttachment:false,
            showSignatureField:false,
            caseTypeName:'',
            generateAuthority:false,
            printPurchaseRender: 0,
            showPurchasePrint: false,
            allowBranches: false,
            showPV: false,
            saleList: [],
            vouchersList: [],
            rendr: 0,

            company: {
                logoPath: '',
                businessName: localStorage.getItem('nameEnglish'),
                name: localStorage.getItem('companyNameEnglish'),
                phoneNo: localStorage.getItem('companyPhoneNo'),
                companyEmail: localStorage.getItem('companyEmail'),
                address: localStorage.getItem('addressEnglish'),
            },


            folderList: [{
                name: 'All',
                isActive: true,
            }],
            attachmentListFolder: [],
            imageSrc: '',
            renderImg: 0,
            roleName: '',
            formName: '',
            priceLabelId: '',
            isCustomerPriceLabel: false,
            arabic: '',
            show: false,
            isFolder: false,
            show1: false,
            show2: false,
            multipleAddress: false,
            type: '',
            isCashCustomer: false,
            b2b: false,
            b2c: false,
            english: '',
            contactCode: '',
            cashCustomerCode: '',
            colorObj: '',
            download: false,
            isPrint: false,
            printDetails: [],
            isSkype: false,
            printRender: 0,
            salutatioRender: 0,
            randerInput: 0,
            randerDate: 0,
            newAddress: {
                id: '',
                area: '',
                address: '',
                city: '',
                country: '',
                billingZipCode: '',
                latitude: '',
                langitutue: '',
                fromTime: '',
                toTime: '',
                billingPhone: '',
                deliveryHolidays: [],
                type: '',
                add: 'Add',
                isActive: false,
                isDefault: false,
                isOffice: false,
                allHour: false,
                allDaySelection: false,
            },
            caseListRanderer:0,
            newCustomer: {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                multipleAddress: false,
                isAddressOnAll: false,
                priceLabelId: '',
                prefix: '',
                englishName: '',
                arabicName: '',
                companyNameEnglish: '',
                companyNameArabic: '',
                customerDisplayName: '',
                telephone: '',
                email: '',
                registrationDate: '',
                category: '',
                customerType: '',
                customerGroup: '',
                contactNo1: '',
                accountId: '',

                commercialRegistrationNo: '',
                vatNo: '',
                customerGroupId: '',
                currencyId: '',
                regionId: '',
                taxRateId: '',
                customerCode: '',
                website: '',

                billingAttention: '',
                billingCountry: '',
                billingZipCode: '',
                billingPhone: '',
                billingArea: '',
                billingAddress: '',
                billingCity: '',
                billingFax: '',

                contactProductModels:[],
                shippingAttention: '',
                shippingCountry: '',
                shippingZipCode: '',
                shippingPhone: '',
                shippingArea: '',
                shippingAddress: '',
                shippingCity: '',
                shippingFax: '',

                contactPersonList: [{
                    prefix: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    mobile: ''
                }],
                deliveryAddressList: [],
                productList: [],
                remarks: '',
                isCustomer: true,
                isVendor: false,
                isActive: true,
                isCashCustomer: true,

                paymentTerms: '',
                deliveryTerm: '',
                creditLimit: '',
                creditPeriod: '',

                attachmentList: [],
                signatureType: '',
                signatureName: '',
                signaturePath: '',
                productId: '',
                profileImage: '',
                isCaseCustomer: true
            },
            loading: false,
            isEdit: false,
            language: 'Nothing',
            showView: false,
            documentpath: '',
            emailCompoese: {
                EmailTo: [],
                subject: '',
                description: '',
                companyName: '',
                buttonName: '',
                emailPath: '',
            },
            ccOptions: [],
            isCaseUser: false
        }
    },
    validations: {

        newCustomer: {
            customerType: {
            },
            englishName: {
                required,

                maxLength: maxLength(250)
            },
            arabicName: {
                maxLength: maxLength(250)
            },
            vatNo: {

                maxLength: maxLength(250)
            },
            paymentTerms: {
            },
            customerDisplayName: {
            },
        }
    },

    methods: {
        stripePayment:function(contact){
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }



            root.$https.post('/Case/StripPayment?amount=' + contact.dueAmount + '&id='+ contact.id + '&productName=' + contact.productName, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if(response.data != null){
                        window.location.href = response.data.url;
                    }else{
                        console.log('Something went wrong');
                    }
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });

        },
        onCloseLoa:function(){
            this.showLoa = false;
        },
        viewLoa:function(sale){
            this.cusSaleDetail = sale;
            this.showLoa = true;
        },
        attachmentSave: function (attachment) {
            debugger;
            console.log(attachment)
                //this.chequeAndGurantee.attachmentList = attachment;
                this.isShowAttachment = false;
            },
            viewAttachment: function (sale) {
                this.cusSaleDetail = sale;
                this.caseId = sale.id;
                this.isShowAttachment = true;
            },
        CloseVoucher: function () {
            this.showVoucher = false;
            this.getCustomerDetail();
        },
        makePayment:function(sale){
            this.cusSaleDetail = sale;
            this.showVoucher = true;
        },
        profileImageChange:function(){
            this.uploadImage(true)
        },
        onSignatureTypeChange:function(){
            this.newCustomer.signatureName = '';
            this.newCustomer.signaturePath = '';
        },
        getValue:function(value){
            this.newCustomer.productId = value.id;
            this.caseTypeName = value.name;
        },
        EditCustomer: function () {
            debugger;
            this.isEdit = true;


        },
        Cancel: function () {
            this.isEdit = false;


        },
        submitForm() {
            // Handle the form submission, e.g., send data to an API
            console.log(this.formData);
        },
        addProduct: function (productId) {
            this.newCustomer.productId = productId;
        },

        removeImage: function () {
            this.imageSrc = '';
            this.newCustomer.signaturePath = '';
            this.renderImg++;

        },
        uploadImage: function (isProfile) {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }

            var file = this.$refs.imgupload.files;

            var fileData = new FormData();

            for (var k = 0; k < file.length; k++) {
                fileData.append("files", file[k]);
            }

            this.imageSrc = URL.createObjectURL(this.$refs.imgupload.files[0]);

            root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        if(isProfile){
                            root.newCustomer.profileImage = response.data;

                        }
                        else{
                            root.newCustomer.signaturePath = response.data;
                        }
                    }
                },
                    function () {
                        this.loading = false;
                        root.$swal({
                            title: root.$t('AddCurrency.Error'),
                            text: root.$t('AddCurrency.SomethingWrong'),
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            buttonsStyling: false
                        });
                    });
        },
        async generatePDF() {
            const html = document.querySelector('#inventoryDetailReport'); // Element to be converted
            const canvas = await html2canvas(html);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('download.pdf');
        },



        onClose: function () {
            this.showPv = false;
            this.paymentVoucherClick();
        },
        paymentVoucherClick: function () {


            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            let receiptName = 'BankReceipt'
            root.$https.get('PaymentVoucher/PaymentVoucherList?paymentVoucherType=' + receiptName + '&status=' + 'Approved' + '&contactId=' + this.newCustomer.accountId + '&isDashboard=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.vouchersList = response.data.results.paymentVouchers;

                }
            });
        },

        CloseModel: function () {
            this.showView = false;
        },
        getDate: function (date) {
            return moment(date).format('LLL');
        },




        PrintView: function () {
            this.show2 = true;

        },



        GetPriceLabeling: function (val) {
            this.newCustomer.priceLabelId = val.id;
        },
        toDecimal: function (price) {
            return parseFloat(price).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },

        isCashCustomerFunc: function () {

            if (this.newCustomer.isCashCustomer) {

                this.newCustomer.customerType = 'Individual';
                this.newCustomer.paymentTerms = 'Cash';

            } else {
                this.newCustomer.customerType = '';
                this.newCustomer.paymentTerms = 'Credit';
            }
        },
        OpenFolder: function () {
            debugger;

            this.isFolder = true;
            this.show = true;
        },


        GetAutoCodeGenerator: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https
                .get('/Contact/AutoGenerateCode?customerType=' + this.formName, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    if (response.data != null) {

                        root.contactCode = response.data;
                        root.randerInput++;

                    }
                });
        },

        GenerateAndSendCode:function(){
            this.newCustomer.contactProductModel = {
                id:'00000000-0000-0000-0000-000000000000',
                productId: this.newCustomer.productId
            }
            this.SaveCustomer();
        },



        SaveCustomer: function () {

            this.newCustomer.code = this.contactCode;
            this.newCustomer.customerDisplayName = this.newCustomer.englishName;

            if (this.newCustomer.id == '00000000-0000-0000-0000-000000000000') {
                localStorage.setItem('ContactPage', 1);
                localStorage.setItem('CurrentActiveContact', this.newCustomer.isCustomer ? true : false);
            }
            this.loading = true;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }



            this.newCustomer.isCaseCustomer = true;
            root.$https
                .post('/Contact/SaveContact', this.newCustomer, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => {
                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                root.getCustomerDetail();
                            }
                        });
                    } else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                root.getCustomerDetail()
                            }
                        });
                    } else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'There is something wrong. Please contact to support.' : 'هناك شيء ما خاطئ. يرجى الاتصال للدعم.',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }

                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        },

        AddRow: function () {

            this.newCustomer.contactPersonList.push({
                prefix: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                mobile: ''
            });

        },

        createUUID: function () {

            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        RemoveRow: function (index) {
            this.newCustomer.contactPersonList.splice(index, 1);
        },



        RemoveRow1: function (index) {
            this.newCustomer.deliveryAddressList.splice(index, 1);
        },



        getCustomerDetail: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var email = localStorage.getItem('UserName');

            this.$https.get('/Contact/ContactDetail?caseCustomer=true' + '&email=' + email, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    debugger;
                    console.log(response.data)
                    root.newCustomer = response.data;
                    root.formName = 'Profile';
                    root.newCustomer.customerType = 'Profile'
                    root.newCustomer.name = response.data.englishName;
                    root.newCustomer.dob = response.data.registrationDate;
                    root.newCustomer.address = response.data.billingAddress;
                    root.newCustomer.mobile = response.data.telephone;
                    root.newCustomer.email = response.data.email;
                    root.newCustomer.matter = response.data.remarks;
                    root.newCustomer.signature = 'data:image/png;base64,' + response.data.signaturePath;
                    root.newCustomer.printedName = response.data.signatureName;
                    root.newCustomer.date = response.data.signatureDate;
                    root.newCustomer.contactProductModels = response.data.contactProductModels;
                    root.newCustomer.signatureName = response.data.signatureName;
                    root.newCustomer.signaturePath = response.data.signaturePath;
                    root.newCustomer.profileImage = response.data.profileImage;
                    root.newCustomer.accountId = response.data.accountId;
                    root.newCustomer.productId = '';
                    root.showList = true
                    root.generateAuthority = false;
                    root.showSignatureField = false;

                }

            });
        },

        SaveCustomerFirstTime: function () {


        this.loading = true;
        var root = this;
        var token = '';
        if (this.$session.exists()) {
            token = localStorage.getItem('token');
        }


        var customerName = {
            id: '00000000-0000-0000-0000-000000000000',
            code: '',
            multipleAddress: false,
            isAddressOnAll: false,
            priceLabelId: '',
            prefix: '',
            englishName: '',
            arabicName: '',
            companyNameEnglish: '',
            companyNameArabic: '',
            customerDisplayName: '',
            telephone: '',
            email: '',
            registrationDate: '',
            category: '',
            customerType: '',
            customerGroup: '',
            contactNo1: '',
            accountId: '',

            commercialRegistrationNo: '',
            vatNo: '',
            customerGroupId: '',
            currencyId: '',
            regionId: '',
            taxRateId: '',
            customerCode: '',
            website: '',

            billingAttention: '',
            billingCountry: '',
            billingZipCode: '',
            billingPhone: '',
            billingArea: '',
            billingAddress: '',
            billingCity: '',
            billingFax: '',

            shippingAttention: '',
            shippingCountry: '',
            shippingZipCode: '',
            shippingPhone: '',
            shippingArea: '',
            shippingAddress: '',
            shippingCity: '',
            shippingFax: '',

            contactPersonList: [{
                prefix: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                mobile: ''
            }],
            deliveryAddressList: [],
            productList: [],
            remarks: '',
            isCustomer: true,
            isVendor: false,
            isActive: true,
            isCashCustomer: true,

            paymentTerms: '',
            deliveryTerm: '',
            creditLimit: '',
            creditPeriod: '',

            attachmentList: [],
            userId: '',
            isCaseCustomer: true
        };
        customerName.userId = localStorage.getItem('UserId')

        root.$https
            .post('/Contact/SaveContact', customerName, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(response => {
                console.log(response);
                root.getCustomerDetail();
            })
            .catch(error => {
                console.log(error)
                root.$swal.fire({
                    icon: 'error',
                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                    text: error.response.data,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });

                root.loading = false
            })
            .finally(() => root.loading = false)
        },



    },
    created: function () {


        this.roleName = localStorage.getItem('RoleName');
        if (this.$route.query.formName == 'CaseCustomer' && this.roleName == 'User') {
            this.SaveCustomerFirstTime();
           




        }
        else {
            this.formName = this.$route.query.formName;
            this.newCustomer.customerType = this.formName
            this.$emit('input', this.$route.name)
            if (this.$route.query.data != undefined) {
                this.newCustomer = this.$route.query.data;
                this.cashCustomerCode = this.newCustomer.code;
                this.contactCode = this.newCustomer.code;
                this.newCustomer.attachmentList.forEach(x => {
                    const existingFolder = this.folderList.find(folder => folder.name === x.description);

                    // If not found, push to folderList
                    if (!existingFolder) {
                        this.folderList.push({
                            name: x.description,
                            isActive: false
                        });
                    }

                });
                this.randerInput++;

            } else {
                this.GetAutoCodeGenerator();
                this.newCustomer.registrationDate = moment().format('llll');
                if (localStorage.getItem('IsCustomeCredit') != 'true') {
                    this.newCustomer.paymentTerms = (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Cash' : 'نقد'
                } else {
                    this.newCustomer.paymentTerms = (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Credit' : 'آجل'
                }


            }

        }


    },
    mounted: function () {
        this.currency = localStorage.getItem('currency')
        this.newCustomer.isCustomer = this.formName == 'Customer' ? true : false;
        this.newCustomer.isCashCustomer = this.newCustomer.isCustomer ? this.newCustomer.isCashCustomer : false
        this.isCaseUser = localStorage.getItem('IsCaseUser') == 'true' ? true : false;
        this.language = this.$i18n.locale;
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');

        this.multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;

        this.isCustomerPriceLabel = localStorage.getItem('IsCustomerPriceLabel') == 'true' ? true : false;
        
    }
})
</script>
