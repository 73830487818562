<template>
    <div class="row account-body accountbg ">

        <div class="row vh-100 d-flex justify-content-center">
            <div class="col-12 align-self-center">
                <div class="row">
                    <div class="col-lg-5 mx-auto">
                        <div class="card">
                            <div class="card-body p-0 auth-header-box">
                                <div class="text-center p-3">
                                    <a href="index.html" class="logo logo-admin">
                                        <img src="assets/images/logo-sm-dark.png" height="50" alt="logo" class="auth-logo">
                                    </a>
                                    <h4 class="mt-3 mb-1 fw-semibold text-white font-18">Let's Get Started Dastone</h4>
                                    <p class="text-muted  mb-0">Sign in to continue to Dastone.</p>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="tab-pane px-3 pt-3" id="Register_Tab" role="tabpanel">
                                    <div class="col-sm-12">
                                        <label>{{ $t('AddSignUp.UserEmail') }} :<span class="text-danger"> *</span></label>
                                        <div v-bind:class="{ 'has-danger': $v.loginDetails.email.$error }">
                                            <input class="form-control" v-model="$v.loginDetails.email.$model"
                                                />
                                            <span v-if="$v.loginDetails.email.$error" class="error text-danger">
                                                <span v-if="!$v.loginDetails.email.required"> {{
                                                    $t('AddSignUp.RegisterUser_Error_Required_EmailID') }} </span>
                                                <span v-if="!$v.loginDetails.email.email"> {{
                                                    $t('AddSignUp.RegisterUser_Error_Format_EmailID') }} </span>
                                            </span>
                                            
                                        </div>
                                    </div>


                                    <div class="col-sm-12">
                                        <label> {{ $t('AddSignUp.Password') }} :<span class="text-danger"> *</span></label>
                                        <input id="password" v-model="$v.loginDetails.password.$model" name="password"
                                            type="password" class="form-control"
                                            v-bind:placeholder="$t('AddSignUp.Password')" aria-label="Recipient's username"
                                            aria-describedby="button-addon2">

                                    </div>
                                    <div class="col-sm-12">
                                        <label>User Name: <span class="text-danger"> *</span></label>
                                        <div v-bind:class="{ 'has-danger': $v.loginDetails.userName.$error }">
                                            <input pattern="^\S*$" class="form-control" v-model="$v.loginDetails.userName.$model"
                                                />
                                            <span v-if="$v.loginDetails.userName.$error" class="error text-danger">
                                                <span v-if="!$v.loginDetails.userName.required"> User name is required </span>
                                                
                                            </span>
                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Full Name: </label>
                                        <input class="form-control" v-model="loginDetails.firstName" />
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Phone Number: </label>
                                        <input class="form-control" type="number" v-model="loginDetails.phoneNumber" />
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Alternative Phone: </label>
                                        <input class="form-control" type="number" v-model="loginDetails.clientNo" />
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Address: </label>
                                        <textarea class="form-control" v-model="loginDetails.touchScreen" />
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="m-3 text-center text-muted">
                                                        <p class="mb-0">Already Signup than click login? <a
                                                                v-on:click="GotoPage('/')"
                                                                class="text-primary ms-2">Free Resister</a></p>
                                                    </div>
                                                        <button v-bind:disabled="$v.loginDetails.$invalid" v-on:click="SaveLoginDetails"
                                                            class="btn btn-primary w-100 waves-effect waves-light"
                                                            type="button">
                                                            <i v-if="loading"
                                                                class="la la-refresh text-secondary la-spin progress-icon-spin"
                                                                style="font-size:.8125rem;"></i>
                                                            <span v-else>Register <i
                                                                    class="fas fa-sign-in-alt ms-1"></i></span>
                                                        </button>
                                                    </div><!--end col-->


                                </div>
                                <!-- Tab panes -->
                                
                            </div><!--end card-body-->

                        </div><!--end card-->
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end col-->
        </div><!--end row-->
        
    </div>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import { required, email } from 'vuelidate/lib/validators';
export default ({
    mixins: [clickMixin],
    data: function () {
        return {
            loading: false,
            loginDetails: {
                id: '00000000-0000-0000-0000-000000000000',
                email: '',
                companyId: '20639255-293D-4D4D-389C-08DC08EF9886',
                password: '',
                userName: '',
                firstName: '',
                phoneNumber: '',
                clientNo: '',
                touchScreen: '',
                isSupervisor: true
            }
        }
    },
    validations: {
        loginDetails: {
            password:
            {
                required,
                strongPassword(password) {
                    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/.test(password);
                }
            },

            email:
            {
                required,
                email
            },
            userName:
            {
                required
            },

        }
    },
    methods: {
        GotoPage: function (link) {
            this.$router.push({ path: link });
        },


        showPassword() {
            if (this.password === "password") {
                this.password = "text";
                this.eyeValue = true;
            }

        },
        hidepassword() {
            this.password = "password"
            this.eyeValue = false;
        },
        showPassword1() {

            if (this.password1 === "password") {
                this.password1 = "text";
                this.eyeValue1 = true;
            }

        },
        hidepassword1() {

            this.password1 = "password"
            this.eyeValue1 = false;
        },



        Cancel: function () {
            this.$router.push({
                path: '/signUp',

            })
        },


        SaveLoginDetails: function () {

            this.loading = true;
            var root = this;
            var token = '';

            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https
                .post('/account/SaveUser', this.loginDetails, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    debugger;
                    if (response.data != "Company no Exist" && response.data != "Email Already Exist" && response.data != "UserName already Exist") {
                        root.loading = false
                        root.info = response.data.bpi
                        root.$router.push('/')
                        
                    }
                    else {
                        alert(response.data);

                        root.loading = false
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Error',
                                text: 'Email Already Exist'
                            });
                        
                    }
                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'Server Error',
                            text: error,
                        });

                    this.loading = false
                })
                .finally(() => this.loading = false)
        }
    },
    created: function () {
        this.$emit('input', this.$route.name);

    },
    mounted: function () {


    }
})
</script>
<style scoped></style>